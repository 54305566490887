<template>
  <div class="page">
    <b-card>
      <b-button
        variant="outline-primary"
        class="mb-2"
        @click="$router.push({name: 'Create Client'})"
      >
        <feather-icon icon="PlusIcon" />
        Add New Client
      </b-button>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="users"
      >
        <template #cell(name)="data">
          <router-link :to="'/superadmin/billings/' + data.item.id">
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(isAdmin)="data">
          <!-- {{ data.value === 1 ? 'Yes' : 'No' }} -->
          <div>
            <feather-icon
              v-if="data.value !== 1"
              icon="XIcon"
              size="20"
              class="redIcon"
            />
            <feather-icon
              v-else
              icon="CheckIcon"
              size="20"
              class="greenIcon"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BButton,
  },
  data() {
    return {
      fields: ['month', 'year', 'outstanding', 'balance', 'actions'],
      users: [{
        id: 1,
        month: '09',
        year: '2021',
        outstanding: '',
        balance: '-',
      }],
    }
  },
}
</script>

<style>

</style>
